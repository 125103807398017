export const communityCardData = [
  {
    title: 'Contact',
    description: 'Have any questions? Get in touch!',
    headingOne: 'Join the Community',
    iconOne: 'stack-exchange',
    linkTextOne: 'Stack Exchange',
    linkOne: 'https://substrate.stackexchange.com',
    headingTwo: 'Contribute to the Devhub',
    iconTwo: 'github',
    linkTextTwo: 'Devhub Github',
    linkTwo: 'https://github.com/substrate-developer-hub',
  },
  {
    title: 'Seminars & Events',
    description: 'Get insights from others building on Substrate, get your questions answered, and build your network.',
    headingOne: 'Participate',
    iconOne: 'graduation',
    linkTextOne: 'Substrate Seminar',
    linkOne: `/ecosystem/resources/seminar`,
    headingTwo: 'Learn more',
    iconTwo: 'calendar',
    linkTextTwo: 'Events',
    linkTwo: 'https://www.parity.io/events',
  },
];
